import { Link } from "gatsby";
import React from "react";
import SEO from "../components/seo";

function NotFoundPage({ location }) {
  return (
    <>
      <SEO title="404: Not found" location={location} />
      <div>
        <h2 className="bg-yellow-400 text-2xl font-semibold inline-block my-8 p-3">
          Oops, that page does not exist.
        </h2>
        <h3>
          How about we Cha Cha Cha back{" "}
          <Link to="/" className="text-highlight hover:underline">
            home
          </Link>
          ?
        </h3>
      </div>
    </>
  );
}

export default NotFoundPage;
